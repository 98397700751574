import { InternalError } from "@gadgetinc/errors";
import type { Exchange } from "@urql/core";
import { isFunction, memoize } from "lodash";
import { stringify } from "qs";
import { cacheExchange, createClient, fetchExchange } from "urql";
import { pipe, tap } from "wonka";
import type { EnvironmentSlug, LegacyEnvironmentName } from "./Environment";
import { graphqlDocumentName } from "./graphqlUtils";

export const urlParamExchange: Exchange = ({ forward }) => {
  return (ops$) =>
    pipe(
      ops$,
      tap((op) => {
        if (op.context.url && op.query && !op.context.url.includes("?")) {
          const params = {
            operation: graphqlDocumentName(op.query) || "unknown",
          };

          op.context = {
            ...op.context,
            url: op.context.url + `?` + stringify(params),
          };
        }
      }),
      forward
    );
};

/**
 * Tags all outgoing query operations as from the editor with the x-gadget-client header, so we can suppress logging in the backend. Mutations are left untouched so they show up clearly in logs.
 **/
export const markAsEditorExchange: Exchange = ({ forward }) => {
  return (ops$) =>
    pipe(
      ops$,
      tap((op) => {
        if (isFunction(op.context.fetchOptions)) {
          throw new InternalError("Can't set editor details on an options with fetch options defined as a function");
        }
        op.context.fetchOptions ??= {};
        op.context.fetchOptions.headers ??= {};
        (op.context.fetchOptions.headers as any)["x-gadget-client"] ??= "editor";
        if (op.kind == "query") {
          (op.context.fetchOptions.headers as any)["x-log-level"] ??= "20"; // UserspaceLogLevel.DEBUG
        }
      }),
      forward
    );
};

/**
 * Tags all outgoing query operations as from the editor with the x-gadget-environment header which tells the backend which env to process the request against.
 * In the editor, we always send requests to the main production domain which has the user's auth cookie on it. We need this header to indicate what the real env to use is.
 **/
export const currentEnvironmentExchange = (currentEnvironment: LegacyEnvironmentName | EnvironmentSlug): Exchange => {
  return ({ forward }) => {
    return (ops$) =>
      pipe(
        ops$,
        tap((op) => {
          if (isFunction(op.context.fetchOptions)) {
            throw new InternalError("Can't set environment on an options with fetch options defined as a function");
          }

          op.context.fetchOptions ??= {};
          op.context.fetchOptions.headers ??= {};
          (op.context.fetchOptions.headers as any)["x-gadget-environment"] ??= currentEnvironment;
        }),
        forward
      );
  };
};

/**
 * Tags subscription operations with an extension to mark them as completed on the last message when they are complete
 */
export const teardownExchange: Exchange = ({ forward }) => {
  return (ops$) =>
    pipe(
      ops$,
      tap((op) => {
        if (op.kind === "teardown") {
          op.context.torndown = true;
        }
      }),
      forward
    );
};

export const getAppUrqlClient = memoize((environment: LegacyEnvironmentName | EnvironmentSlug) =>
  createClient({
    url: "/api/graphql",
    exchanges: [currentEnvironmentExchange(environment), cacheExchange, urlParamExchange, markAsEditorExchange, fetchExchange],
  })
);
