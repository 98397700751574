import type { DocumentNode, OperationDefinitionNode } from "graphql";
import { find, findLast } from "lodash";

export const graphqlDocumentName = (doc: DocumentNode) => {
  const lastDefinition: OperationDefinitionNode | undefined = findLast(doc.definitions, { kind: "OperationDefinition" }) as any;
  if (lastDefinition) {
    if (lastDefinition.name) {
      return lastDefinition.name.value;
    }
    const firstSelection = find(lastDefinition.selectionSet.selections, { kind: "Field" }) as any;
    return firstSelection.name.value;
  }
};

export const graphqlDocumentOperationSelection = (doc: DocumentNode) => {
  const lastDefinition = findLast(doc.definitions, { kind: "OperationDefinition" });
  if (lastDefinition && typeof lastDefinition === "object" && "selectionSet" in lastDefinition) {
    const firstSelection = find(lastDefinition.selectionSet.selections, { kind: "Field" });

    if (firstSelection && typeof firstSelection === "object" && "selectionSet" in firstSelection && "name" in firstSelection) {
      return firstSelection;
    }
  }
};

export const graphqlDocumentOperationType = (doc: DocumentNode) => {
  const lastDefinition: OperationDefinitionNode | undefined = findLast(doc.definitions, { kind: "OperationDefinition" }) as any;
  if (lastDefinition) {
    return lastDefinition.operation;
  }
};
